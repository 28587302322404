import { Component, OnInit, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor() {
    // private analyticsService: AnalyticsService, // private globalService: GlobalService, // private appService: AppService, // private utmService: UtmService, // private messagingservice: MessagingService, // private utilService: UtilService, // private i18nService: I18nService, // private translateService: TranslateService, // private titleService: Title, // private activatedRoute: ActivatedRoute, // private router: Router, // @Inject(PLATFORM_ID) private platformId: Object,
    // router.events
    //   .pipe(
    //     distinctUntilChanged((previous: any, current: any) => {
    //       this.utilService.previousUrl = previous.url;
    //       const tree = router.parseUrl(router.url);
    //       if (current instanceof NavigationEnd) {
    //         /** for triggering animation */
    //         if (!tree.fragment) {
    //           // this.routeAnimation = current.url;
    //           return previous.url === current.url;
    //         } else if (tree.fragment != '_=_') {
    //           //'=' is an invalid selector for querySelector, hence checking for '_=_' returned by Facebook login redirect
    //           const element = document.querySelector('#' + tree.fragment);
    //           if (element) {
    //             element.scrollIntoView();
    //           }
    //         }
    //       }
    //       return true;
    //     }),
    // )
    // .subscribe((event: any) => {
    //   if (isPlatformBrowser(platformId)) {
    //     const url = window.location.href;
    //     if (
    //       url.indexOf('/order') == -1 ||
    //       url.indexOf('/cart') != -1 ||
    //       url.includes('addtocart_from_mail')
    //     ) {
    //       this.utmService.utmTracking(url);
    //     }
    //     this.globalService.checkLoginRoutes(this.router.url.split('?')[0]);
    //     let n = window.location.pathname + window.location.search;
    //     if (n.includes('addtocart_from_mail')) {
    //       this.globalService.addToCartFromEmail(n).subscribe((res) => {
    //         window.location.href = '/order/cart';
    //       });
    //     }
    //   }
    // });
  }

  ngOnInit() {}

  ngOnDestroy() {}
}
