import { UrlSegment, UrlSegmentGroup, Route } from '@angular/router';

export function PdpUrlMatcher(
  segments: UrlSegment[],
  segmentGroup: UrlSegmentGroup,
  route: Route,
) {
  const paramName: string = 'sku';
  const regex: RegExp = /^([\w-]+)-([a-zA-Z0-9.-]+)$/;
  const parts = regex;
  const posParams: {
    [key: string]: UrlSegment;
  } = {};
  const consumed: UrlSegment[] = [];

  const currentIndex = 0;

  const current = segments[currentIndex];

  const part = parts;
  if (!current || !part.test(current.path)) {
    return null;
  }
  const sku = current.path.slice(
    current.path.lastIndexOf('-') + 1,
    current.path.length,
  );

  if (!sku?.includes('.')) {
    return null;
  }

  posParams[paramName] = new UrlSegment(sku, {});
  consumed.push(current);

  if (
    route.pathMatch === 'full' &&
    (segmentGroup.hasChildren() || currentIndex < segments.length)
  ) {
    return null;
  }
  return { consumed, posParams };
}
