import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { appConfig } from '@app/app.config';
// import { environment } from '@env/environment';
// import * as Sentry from '@sentry/angular';

// Sentry.init({
//   dsn: environment.sentryDSN,
//   enabled: environment.production,
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   tracesSampleRate: 1.0,
//   tracePropagationTargets: [environment.hostUrl],
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
