import { UrlSegment, UrlSegmentGroup, Route } from '@angular/router';

export function ListingUrlMatcher(
  segments: UrlSegment[],
  segmentGroup: UrlSegmentGroup,
  route: Route,
) {
  const paramName: string = 'url';

  // all these url are for listing page only.
  const regexpressions: Array<string[]> = [
    // all products.
    ['all-products'],
    // power brands
    ['power-brands'],
    // clearence sale
    ['offers'],
    // japanese-skus
    ['japanese-skus'],
    // search page.
    ['search'],
    ['lp', ''],
    // c0 all products page.
    ['([\\w-]+)-(\\d+)', 'all-products'],
    // category page
    ['([\\w-]+)-(\\d+)', '([\\w-]+)-(\\d+)'],
    ['([\\w-]+)-(\\d+)', '([\\w-]+)-(\\d+)', '([\\w-]+)-(\\d+)'],
    // type urls.
    ['([\\w-]+)-(\\d+)', '([\\w-]+)-(\\d+)', 'type', '([\\w|\\W]+)'],
    //category brand urls
    ['([\\w-]+)-(\\d+)', '([\\w-]+)-(\\d+)', '(brand-[\\w-]+)'],
    ['([\\w-]+)-(\\d+)', '(brand-[\\w-]+)'],
    // brands page.
    ['brands', '([\\w-]*)', '([\\w-]+)-(\\d+)'],
    ['brands', '([\\w-]*)', 'featured-products'],
    // brand category page.
    ['brands', '([\\w-]*)', '([\\w-]+)-(\\d+)', '([\\w-]+)-(\\d+)'],
  ];

  const posParams: {
    [key: string]: UrlSegment;
  } = {};
  let consumed: UrlSegment[] = [];

  let currentIndex = 0;
  let failCount = 0;

  let url = '';
  const param = new UrlSegment(url, {});
  const segmentsWithoutParam = segments.filter(
    (item) => item.path && !item.path.startsWith('?'),
  );

  for (let j = 0; j < regexpressions.length; j++) {
    failCount = 0;
    currentIndex = 0;
    const regex = regexpressions[j];
    if (regexpressions[j].length !== segmentsWithoutParam.length) {
      failCount++;
      continue;
    }
    for (let i = 0; i < segmentsWithoutParam.length; ++i) {
      const current = segmentsWithoutParam[currentIndex];

      if (!new RegExp(regex[i]).test(current.path)) {
        failCount++;
        break;
      }

      url += '/' + current.path;
      param.path = url;
      posParams[paramName] = param;
      consumed.push(current);
      currentIndex++;
    }
    if (failCount === 0) {
      break;
    } else {
      url = '';
      param.path = '';
      posParams[paramName] = param;
      consumed = [];
      currentIndex = 0;
    }
  }

  if (failCount !== 0) {
    return null;
  }

  if (
    route.pathMatch === 'full' &&
    (segmentGroup.hasChildren() || currentIndex < segments.length)
  ) {
    return null;
  }

  return { consumed, posParams };
}
