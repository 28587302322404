import { Route, UrlSegment, UrlSegmentGroup } from '@angular/router';

export function C0UrlMatcher(
  segments: UrlSegment[],
  segmentGroup: UrlSegmentGroup,
  route: Route,
) {
  const paramName: string = 'url';
  const regex: RegExp = /^(([\w-]+)-(\d+))(\/[?].*)?$/;

  const parts = regex;
  const posParams: {
    [key: string]: UrlSegment;
  } = {};
  const consumed: UrlSegment[] = [];

  let currentIndex = 0;

  let url = '';
  const param = new UrlSegment(url, {});

  for (let i = 0; i < segments.length; ++i) {
    if (currentIndex >= segments.length) {
      return null;
    }
    const current = segments[currentIndex];

    const part = parts;
    if (!part.test(current.path) || segments.length !== 1) {
      return null;
    }

    url += '/' + current.path;
    param.path = url;
    posParams[paramName] = param;
    consumed.push(current);
    currentIndex++;
  }

  if (
    route.pathMatch === 'full' &&
    (segmentGroup.hasChildren() || currentIndex < segments.length)
  ) {
    return null;
  }

  return { consumed, posParams };
}
