import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import {
  provideRouter,
  Router,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
} from '@angular/router';

import {
  provideClientHydration,
  withEventReplay,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import { routes } from './app.routes';
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { apiPrefixInterceptor, httpInterceptor, SeoMetaService } from './core';
// import * as Sentry from '@sentry/angular';

const seoProviders = [SeoMetaService];

const sentryProviders = [
  // {
  //   provide: ErrorHandler,
  //   useValue: Sentry.createErrorHandler(),
  // },
  // {
  //   provide: Sentry.TraceService,
  //   deps: [Router],
  // },
  // {
  //   provide: APP_INITIALIZER,
  //   useFactory: () => () => {},
  //   deps: [Sentry.TraceService],
  //   multi: true,
  // },
];

export const appConfig: ApplicationConfig = {
  providers: [
    ...seoProviders,
    ...sentryProviders,
    provideExperimentalZonelessChangeDetection(),
    // provideZoneChangeDetection({ eventCoalescing: true }),
    provideAnimations(),
    provideHttpClient(
      withFetch(),
      withInterceptors([apiPrefixInterceptor, httpInterceptor]),
    ),
    provideRouter(
      routes,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({ scrollPositionRestoration: 'top' }),
    ),
    provideClientHydration(
      withEventReplay(),
      withHttpTransferCacheOptions({ includePostRequests: true }),
    ),
  ],
};
